import addToMailchimp from 'gatsby-plugin-mailchimp'
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"

export default class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: ' ', result: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    this.setState({message: 'Te has suscrito!'})
    this.setState({email: ' '})
  }
  
handleChange = event => {
    this.setState({ email: event.target.value })
  }
render() {
  return (
    <>
    <CardTitle className="text-center  mb-3">
   <a><h1> Newsletter </h1></a>
  </CardTitle>
  <h1>{this.state.message}</h1>
  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
        <Input
          id="outlined-email-input"
          label="Email"
          type="email"
          name="email"
          placeholder="Email adress"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <br />
        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
       < button  className="btn btn-outline-success text-uppercase">
         Subscribe!
          </button>
          </motion.div>
        </FormGroup>
      </Form>
      </>
    )
  }
}