import PropTypes from 'prop-types'
import React from "react"
import {
  Collapse,
  Navbar,
 
} from 'reactstrap'
import '@fortawesome/fontawesome-svg-core/styles.css';
import DarkToggle from '../components/DarkToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import useSound from 'use-sound'
import boopSfx from '../images/boopSfx.mp3'
import {ButtonLink, ButtonGroup}  from '../styles/Button'
import { motion } from "framer-motion"
import {faMyCarIcon} from './svG'



const BoopButton = () => {
  const [play] = useSound(boopSfx);
  
  return <DarkToggle onClick={play} />;
};



const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: 0  },
 
};


const variantss = {
  transition:  0.1 ,
  open: { opacity: 0 },
  closed: { opacity: 1  },
 
};



class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  
  render() {
    
    
    return ( 
      
     <>
      
    
      
        <Navbar  fixed="top" >
       
     
        <motion.div class="dark"
      
      
      whileHover={{ scale: 1.2, rotate: 180 }}

    animate={{
      transition:  0.1 ,
    
      rotate: [45, 90, 270, 270, 0],
      borderRadius: ["20%", "20%", "50%", "50%", "20%"],

    }}
  >
   
   <BoopButton />

      
      </motion.div>
      
     <div class="resizeme">
     <a href="/">
     <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >

    <FontAwesomeIcon icon={faMyCarIcon}  />
    
  
    </motion.div>
    </a>
    </div>
   
   <motion.nav animate={this.state.isOpen ? "open" : "closed"}
      variants={variantss} > 
      <motion.div
      

      whileHover={{ scale: 1.1, rotate: 45 }}

      class="astro"
  >



          <FontAwesomeIcon     icon={faUserAstronaut}  onClick={this.toggle} navbar />
           
          </motion.div>
     
          </motion.nav>
          
          <motion.nav class="mnav" animate={this.state.isOpen ? "open" : "closed"}
      variants={variants} > 
          <Collapse isOpen={this.state.isOpen} >

      

          
          <motion.div     class="astro"
  >
      
          <FontAwesomeIcon   icon={faUserAstronaut}  onClick={this.toggle} navbar spin/>
          </motion.div>
          
  <ButtonGroup

  >
  
  
          
      
   
                
                <motion.div
      
      
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
     
  >
   
    
  
                  <ButtonLink    to="/" href="/team"><h4>TEAM</h4></ButtonLink>
                  </motion.div>
                  <motion.div
      
      
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
     
  >
   
    
  
                  <ButtonLink    to="/" href="/boringmath"><h4>BORING MATH</h4></ButtonLink>
                  </motion.div>

                
               

               
                <motion.div
      
      
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
     
  >
                <ButtonLink to="/" href="/tags"><h4>TAGS</h4></ButtonLink>
                </motion.div>
              
              
               
                <motion.div
      
      
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
     
  >
                <ButtonLink to="/"  href = "/about"><h4>ABOUT</h4></ButtonLink>

                </motion.div>
                

           
             
              </ButtonGroup>
            </Collapse>
            </motion.nav>
        </Navbar>
        
        </>

       




    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
