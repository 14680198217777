import React from 'react'
import {
  Card,
  CardTitle,
  CardBody,
 
} from 'reactstrap'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import MailChimpForm from '../components/MailchimpForm'
import {PPost} from '../components/PostExcerpt/styles'

const Sidebar = () => (
  <div >
    

    <PPost>
    <CardBody>
       
       <MailChimpForm/>
       </CardBody>
      </PPost>
      <PPost>
        
        <CardTitle className="text-center text-uppercase mb-3">
          Posts Recientes
        </CardTitle>
        <StaticQuery
          query={sidebarQuery}
          render={data => (
            <>
              {data.allMdx.edges.map(({ node }) => (
                <div class="team">
                <Card key={node.id}>
                  
                  <Link slug ={ node.fields.slug}>
                    <Img
                      className="card-image-top"
                      fluid={node.frontmatter.image.childImageSharp.fluid}
                    />
                  </Link>
                  <CardBody>
                    <CardTitle>
                      <Link to={'/' + node.fields.slug}>
                        {node.frontmatter.title}
                      </Link>
                    </CardTitle>
                  </CardBody>
                </Card>
                </div>
              ))}
            </>
            
          )}
        />
        
      </PPost>
    
  </div>

  
)

const sidebarQuery = graphql`
  query sidebarQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default Sidebar
