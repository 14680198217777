import React from 'react'

const Footer = () => (
  <div className="site-footer">
    <h2 className="text-center">Homotopías</h2>
    <p className="text-center">Sociales</p>
    <div className="author-social-links text-center">
    <ul>
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100027643854225"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook"
          >
           <i className="fab fa-facebook-f fa-lg" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/M2718281DINA"
            target="_blank"
            rel="noopener noreferrer"
            className="twitter"
          >
            <i className="fab fa-twitter fa-lg" />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            target="_blank"
            rel="noopener noreferrer"
            className="instagram"
          >
            
            <i className="fab fa-instagram fa-lg" />
          </a>
        </li>
        
      </ul>
    </div>
  </div>
)

export default Footer