import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Helmet from 'react-helmet'
import Footer from './Footer'
import Sidebar from './Sidebar'
import '../styles/index.scss'
import { Row, Col, } from 'reactstrap'
import {Ptd, Ptd2} from './PageTitle/styles'
import {Alert} from 'reactstrap'
import Toc from '../components/Toc'
import '@fortawesome/fontawesome-svg-core/styles.css';

const Layout = ({ authorImageFluid, children, pageTitle, postAuthor }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
      
    `}
    
    
    render={data => (
      <>
      <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
          ]}
        >
          <html lang="en" />
          <noscript>{`
            Opps, I cann't work without javascript, will you please enable javascript.
        `}</noscript>
        </Helmet>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
          integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
          crossOrigin="anonymous"
        />
 
     
    
          <Alert className ="title"  color="dark">
          <h1>{pageTitle}</h1>
          </Alert>
          
       

         
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="container" id="content">
          <Row>
            <Col md='9'>{children} </Col>
            <Col md='3'> <Toc />   </Col>

            </Row>
            </div>
      
            <br />
            <br />
            <br />
            
              <Sidebar author={postAuthor} authorFluid={authorImageFluid} />


                
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
